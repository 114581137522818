import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import LeftIcon from "/assets/icons/fontawesome/Left.svg";
import RightIcon from "/assets/icons/fontawesome/Right.svg";
import Layout from "../components/Layout";
import "../style/about.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

const translations = {
  back: {
    en: "Back",
    es: "Back",
    it: "Back",
  },
  readmore: {
    en: "Read more",
    es: "Read more",
    it: "Leggi di più",
  },
};

const AboutPage = ({ data, pageContext, path }) => {
  const [currentCard, setCurrentCard] = useState(1);
  const [currentValueMach9, setCurrentValueMach9] = useState(1);

  // document.addEventListener("DOMContentLoaded", function() {
  //   const peopleDescriptionEle = document.querySelectorAll(".people-description");
  //   const lettersMaxLength = 200

  //   peopleDescriptionEle.forEach(description => {
  //     if (description.textContent.length > lettersMaxLength) {
  //       description.textContent = description.textContent.slice(0, lettersMaxLength) + '...';
  //     }
  //   });
  // })

  const { frontmatter } = data?.markdownRemark;

  const content = frontmatter[pageContext.language || "en"];
  const homeUrl =
    pageContext.language === "en" ? "/" : `/${pageContext.language}/`;

  const [truncatedDescriptions, setTruncatedDescriptions] = useState([]);

  useEffect(() => {
    const lettersMaxLength = 200;
    const crewmembers = content?.crewsection?.crewmembers || [];
    const truncatedDescriptions = crewmembers.map((member) => {
      if (member.description.length > lettersMaxLength) {
        return member.description.slice(0, lettersMaxLength) + "...";
      }
      return member.description;
    });
    setTruncatedDescriptions(truncatedDescriptions);
  }, [content]);

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <div id="about-page">
        <section id="about-hero">
          <div className="section-content">
            <div>
              <div className="catchy">{content.herosection.title1}</div>
              <h2>{content.herosection.title2}</h2>
              <p>{content.herosection.description}</p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={content.herosection.heroimage?.publicURL}
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "35rem",
                  margin: "auto",
                  // filter: "grayscale(1)",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        </section>
        <section id="about-values">
          <div>
            <h2>{content.valuessection.title1}</h2>
            <p>{content.valuessection.description}</p>
          </div>
          <div>
            <div
              id="values-container"
              className="cards"
              style={{
                position: "relative",
                zIndex: "1",
              }}
            >
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="aboutSwiper"
              >
                {content.valuessection.values?.map((value) => (
                  <SwiperSlide>
                    <div className="card">
                      <div
                        style={{
                          backgroundImage: `url(${value.valueimage.publicURL})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          position: "absolute",
                          inset: "0",
                          minHeight: "100%",
                          minWidth: "100%",
                          filter: "opacity(0.35)",
                          zIndex: -1,
                        }}
                      ></div>
                      <h3>{value.imagetitle}</h3>
                      <p>{value.imagedescription}</p>
                      <div className="overlay"></div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              className="projector"
              style={{
                transform: "translate(-50%, -75%) rotate(-90deg)",
                zIndex: 0,
              }}
            ></div>
            <div
              style={{
                backgroundImage: `url(/static/assets/projector.png)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "10rem",
              }}
            ></div>
          </div>
        </section>
        <section id="about-crew">
          <div>
            <div className="catchy">{content.crewsection.title1}</div>
            <h2>{content.crewsection.title2}</h2>
          </div>
          <div id="crew-members-container">
            <div className="bg"></div>
            <div className="left chevron">
              <LeftIcon
                onClick={() => {
                  if (content.crewsection.crewmembers.length <= 1) return;
                  var cardNext;
                  var cardI;
                  if (currentCard === 1) {
                    cardI = content.crewsection.crewmembers.length - 1;
                    cardNext = 0;
                  } else if (currentCard === 0) {
                    cardI = content.crewsection.crewmembers.length - 2;
                    cardNext = content.crewsection.crewmembers.length - 1;
                  } else {
                    cardI = currentCard - 2;
                    cardNext = currentCard - 1;
                  }

                  document.getElementById("crew-members").scroll({
                    left:
                      document.getElementById("crew-members").offsetWidth *
                      cardI,
                    behavior: "instant",
                  });
                  setCurrentCard(cardNext);
                }}
              />
            </div>
            <div className="right chevron">
              <RightIcon
                onClick={() => {
                  if (content.crewsection.crewmembers.length <= 1) return;

                  var nextCard;
                  if (
                    currentCard ===
                    content.crewsection.crewmembers.length - 1
                  ) {
                    nextCard = 0;
                  } else nextCard = currentCard + 1;

                  document.getElementById("crew-members").scroll({
                    left:
                      document.getElementById("crew-members").offsetWidth *
                      currentCard,
                    behavior: "instant",
                  });
                  setCurrentCard(nextCard);
                }}
              />
            </div>
            <div id="crew-members" className="cards">
              {content.crewsection.crewmembers?.map((member, index) => (
                <div className="crew-member card">
                  <img
                    src="/static/assets/memberBg.png"
                    className="memberBg"
                    alt="member bg"
                  />
                  <img
                    src="/static/assets/mobile_about.png"
                    className="memberBgMobile"
                    alt="member bg"
                  />

                  <div style={{ paddingRight: "20px" }}>
                    <div className="catchy">{member.title1}</div>
                    <h3>{member.title2}</h3>
                    <p>{member.title3}</p>
                    <h4>{member.title4}</h4>
                    <p className="people-description">
                      {truncatedDescriptions[index]}
                    </p>
                    <Link to={`${homeUrl}crew?member=${member.title2}`}>
                      <button className="mach9">
                        <div>
                          {translations.readmore[pageContext.language]}
                          <ArrowIcon />
                        </div>
                      </button>
                    </Link>
                  </div>
                  <div className="crew-img">
                    <img src={member.crewimage.publicURL} alt="crew member" />
                    {/* <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      backgroundImage: `url(${member.crewimage.publicURL})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      filter: "grayscale(1)",
                      borderRadius: "15px",
                      minHeight: "80%",
                    }}
                  ></div> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="card-selector card_crew_members">
              {content.crewsection.crewmembers?.map((_, i) => {
                var currentI = currentCard - 1;
                if (currentCard === 0)
                  currentI = content.crewsection.crewmembers.length - 1;

                if (i === currentI)
                  return (
                    <div
                      key={`card-selector-${i}-selected`}
                      className="selected"
                    ></div>
                  );

                return (
                  <div
                    key={`card-selector-${i}`}
                    onClick={() => {
                      document.getElementById("crew-members").scroll({
                        left:
                          document.getElementById("crew-members").offsetWidth *
                          i,
                        behavior: "instant",
                      });
                      setCurrentCard(i + 1);
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { en: { templateKey: { eq: "about-page" } } }) {
      frontmatter {
        en {
          herosection {
            title1
            title2
            description
            heroimage {
              publicURL
            }
          }
          valuessection {
            title1
            description
            values {
              valueimage {
                publicURL
              }
              imagetitle
              imagedescription
            }
          }
          crewsection {
            title1
            title2
            crewmembers {
              title1
              title2
              title3
              title4
              description
              crewimage {
                publicURL
              }
            }
          }
        }
        it {
          herosection {
            title1
            title2
            description
            heroimage {
              publicURL
            }
          }
          valuessection {
            title1
            description
            values {
              valueimage {
                publicURL
              }
              imagetitle
              imagedescription
            }
          }
          crewsection {
            title1
            title2
            crewmembers {
              title1
              title2
              title3
              title4
              description
              crewimage {
                publicURL
              }
            }
          }
        }
        es {
          herosection {
            title1
            title2
            description
            heroimage {
              publicURL
            }
          }
          valuessection {
            title1
            description
            values {
              valueimage {
                publicURL
              }
              imagetitle
              imagedescription
            }
          }
          crewsection {
            title1
            title2
            crewmembers {
              title1
              title2
              description
              crewimage {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
